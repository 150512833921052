import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/components/docs-layout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <img {...{
      "src": "/nice2outline.svg",
      "alt": "nice! logo",
      "style": {
        "height": "150px"
      }
    }}></img>
    <p>{`Welcome to the documentation for all of Nice Keyboards' products.`}</p>
    <p><a parentName="p" {...{
        "href": "https://discord.gg/CHd6hUy"
      }}>{`Join the Discord`}</a>{` for more immediate support, but please read the documentation first.`}</p>
    <p>{`If you find typos, unclear areas, or have suggestions for more documentation, please offer the feedback in `}<a parentName="p" {...{
        "href": "https://discord.gg/CHd6hUy"
      }}>{`the Discord`}</a>{`.`}</p>
    <h3 {...{
      "id": "pages"
    }}>{`Pages`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/docs/nice-nano/"
        }}>{`nice!nano`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/docs/nice-60/"
        }}>{`nice!60`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/docs/wireless-firmware/"
        }}>{`Wireless Keyboard Firmware`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      